var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":"System Texts","title-icon":"ios-build"}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"systemTexts/getTexts","fields":_vm.fields},scopedSlots:_vm._u([{key:"active",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":rowData.item.active ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm._f("activeInactive")(rowData.item.active))+" ")])]}},{key:"isHtml",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanToYesNo")(rowData.item.isHtml))+" ")])]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.createdAt)))])]}},{key:"updatedAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.updatedAt)))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":"Edit"},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" Edit ")])]}}])}),_c('system-text-modal',{ref:"systemTextModal",attrs:{"modal-data":_vm.modalData},on:{"onSaveChanges":_vm.saveChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
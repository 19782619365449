<template>
  <cp-general-modal
    ref="systemTextModal"
    title="Edit System Text"
    title-icon="ion ion-md-create"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      label="Name"
      disabled
    />
    <cp-select
      v-model="model.type"
      name="type"
      label="Type"
      :options="types"
      disabled
    />
    <div>
      <div class="inner-layout switcher-section">
        <span>Active</span>
        <cp-switcher
          :value="model.active"
          name="active"
          disabled
        />
      </div>
      <div class="inner-layout">
        <span>is HTML</span>
        <cp-switcher
          :value="model.isHtml"
          name="isHtml"
          disabled
        />
      </div>
    </div>
    <cp-textarea
      v-model="model.value"
      label="Value"
      name="value"
    />
    <div class="row mt-2">
      <div class="col text-muted">
        <label class="form-label">
          Date Created
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          Date Updated
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpSwitcher from '~/components/common/standalone-components/inputs/cp-switch';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'SystemTextModal',
  components: {
    CpSwitcher,
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpTextarea,
  },
  props: {
    modalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      types: ['system', 'content'],
    };
  },
  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('onSaveChanges', this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
  .inner-layout{
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
  .inner-layout:nth-child(2) {
    margin-left: 20%;
  }
  .switcher-section {
    margin: 10px 0;
  }
</style>

<template>
  <div>
    <cp-head-table
      title="System Texts"
      title-icon="ios-build"
    />
    <cp-table
      ref="cpTable"
      get-data-action="systemTexts/getTexts"
      :fields="fields"
    >
      <template
        slot="active"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :variant="rowData.item.active ? 'success' : 'danger'"
        >
          {{ rowData.item.active | activeInactive }}
        </b-badge>
      </template>
      <template
        slot="isHtml"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.isHtml | booleanToYesNo }}
        </span>
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>
      <template
        slot="updatedAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.updatedAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          title="Edit"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          Edit
        </b-btn>
      </template>
    </cp-table>
    <system-text-modal
      ref="systemTextModal"
      :modal-data="modalData"
      @onSaveChanges="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fields from './fields';
import SystemTextModal from './system-text-modal';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';

export default {
  name: 'SystemTexts',

  metaInfo: {
    title: 'System Texts',
  },

  components: {
    CpHeadTable,
    CpTable,
    SystemTextModal,
  },

  data() {
    return {
      fields,
      modalData: {},
    };
  },

  methods: {
    ...mapActions('systemTexts', ['updateSystemTexts']),
    toEditModal(item) {
      this.modalData = item;
      this.$refs.systemTextModal.$refs.systemTextModal.show();
    },
    saveChanges(data) {
      this.updateSystemTexts({
        textId: data.id,
        body: { value: data.value },
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.systemTextModal.$refs.systemTextModal.hide();
      });
    },
  },
};
</script>
